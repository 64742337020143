import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Footer } from './components/Footer';
import { tours } from './data/TourData';
import './App.css';

const HomePage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTour, setSelectedTour] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const aboutUsRef = useRef(null);
  const walkingToursRef = useRef(null);
  const learnMoreRef = useRef(null);

  const scrollToSection = (elementRef) => {
    elementRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleTourClick = (tour) => {
    setSelectedTour(tour);
    setShowPopup(true);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className='container'>
      <div className='header'>
        <div className='banner'>
          <div className='logo'>
            <img alt='logo' src='logo.png'/>
          </div>
          <button className='dropdown-button' onClick={toggleDropdown}>
            <FontAwesomeIcon icon={faBars} size="2x" />
          </button>
        </div>
        {showDropdown && (
          <div className='nav'>
            <div className='nav-section' onClick={() => scrollToSection(aboutUsRef)}>
              About Us
            </div>  
            <div className='nav-section' onClick={() => scrollToSection(walkingToursRef)}>
              Walking Tours
            </div>
            <div className='nav-section' onClick={() => scrollToSection(learnMoreRef)}>
              Learn More
            </div> 
          </div>
        )}
      </div>

      <div className='hero'>
      </div>

      <div className='about-us' ref={aboutUsRef}>
        <h2>About Us</h2>
        <p>
        We are a collaboration between SWIGGI, the Simon Wiesenthal Genealogical Geolocation Initiative, and Teach the Shoah.
        Together, we aim to link genealogy and geolocation data to tell multitudes of stories from the Holocaust while preserving and 
        commemorating these living environments through our curated tours.
        </p>
      </div>

      <div className='tour' ref={walkingToursRef}>
        <h2>Walking Tours</h2>
        <p>
        Click the links below to access interactive maps accompanied with audio and narrated visuals.
        </p>
        <div className='tour-buttons'>
          {tours.map(tour => (
            <button key={tour.id} onClick={() => handleTourClick(tour)}>
              {tour.district} <br></br> {tour.zone}
            </button>
          ))}
        </div>
      </div>
      
      <div ref={learnMoreRef}> 
        <Footer>
        </Footer>
      </div>
      
      {showPopup && selectedTour && (
        <div className='popup'>
          <p>You selected the following tour:</p>
          <h2><b>{selectedTour.district} {selectedTour.zone}</b></h2>
          <p> It has <b>[{selectedTour.stops}]</b> stops and is <b>[{selectedTour.distance}]</b> long </p>
          <p> Estimated walk time is <b>[{selectedTour.walkingTime}]</b></p>
          <div className='popup-buttons'>
            <button onClick={() => setShowPopup(false)}>Go Back</button>
            <Link to={`/map/${selectedTour.id}`}><button>Open Map</button></Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;