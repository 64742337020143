import React from 'react';

const footerStyle = {
  backgroundColor: '#000000',
  color: 'white',
  padding: '10px 0',
  textAlign: 'center'
};

const linkStyle = {
  color: 'white',
  textDecoration: 'none',
  margin: '0 15px',
  fontSize: '14px',
  fontFamily: 'Inter, sans-serif',
};

const separatorStyle = {
  margin: '0 10px'
};

export const Footer = () => {
  return (
    <footer style={footerStyle}>
      <a
        href="https://simonwiesenthal-galicia-ai.com/swiggi/mainindex.php"
        target="_blank"
        rel="noopener noreferrer"
        style={linkStyle}
        onMouseEnter={(e) => e.target.style.color = '#3498db'}
        onMouseLeave={(e) => e.target.style.color = 'white'}
      >
        Visit SWIGGI
      </a>
      <span style={separatorStyle}>|</span>
      <a
        href="https://www.teachtheshoah.org/"
        target="_blank"
        rel="noopener noreferrer"
        style={linkStyle}
        onMouseEnter={(e) => e.target.style.color = '#3498db'}
        onMouseLeave={(e) => e.target.style.color = 'white'}
      >
        Visit Teach the Shoah
      </a>
    </footer>
  );
};
