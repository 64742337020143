import React from 'react';
import './LoadingSpinner.css'

export const LoadingSpinner = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-content">
        <div className="spinner"></div>
        <p className="loading-text">Hold on...Getting your position...</p>
      </div>
    </div>
  );
};