function deg2rad(deg) {
    return deg * (Math.PI/180)
  }

  function getDistanceFromCoords(lat1, lon1, lat2, lon2) {
    const R = 6371;
    const dLat = deg2rad(lat2-lat1);
    const dLon = deg2rad(lon2-lon1); 
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = R * c;
    return d;
  }

  function calculateNewPoint(start, distance, bearing) {
    const R = 6371e3; // Earth's radius in meters
    const δ = distance / R; // angular distance in radians
    const θ = bearing * Math.PI / 180; // bearing in radians
    const φ1 = start.lat() * Math.PI / 180;
    const λ1 = start.lng() * Math.PI / 180;

    const sinφ2 = Math.sin(φ1) * Math.cos(δ) + Math.cos(φ1) * Math.sin(δ) * Math.cos(θ);
    const φ2 = Math.asin(sinφ2);
    const y = Math.sin(θ) * Math.sin(δ) * Math.cos(φ1);
    const x = Math.cos(δ) - Math.sin(φ1) * sinφ2;
    const λ2 = λ1 + Math.atan2(y, x);

    return {
      lat: φ2 * 180 / Math.PI,
      lng: λ2 * 180 / Math.PI
    };
  };

export { getDistanceFromCoords, calculateNewPoint };