export const tours = [
    {
    id: 1,
    district: 'Leopoldstadt', 
    zone: '[Zone 2]', 
    stops: 8, 
    distance: '2km', 
    walkingTime: '30 minutes',
    center: { 
        lat: 48.21774803393539,
        lng: 16.380806841905153
    },
    locations: [
    {
        id: 1, position: { lat: 48.215732984277956, lng: 16.37537687797315 }, videoUrl:"/zone2/seg_1.mp4", audioUrl: "/zone2/seg_1.mp3", thumbnailUrl: "/zone2/seg_1_tn.jpg", imageUrl: "/zone2/seg_1.jpg",
        address: "Große Schiffgasse 2", locationName: "Former Home of Renata Krochmal", name: "Renata Krochmal",
        description: `In the 1930s, Grosse Schiffgasse 2 was the home of the Kromchal family, Yaakov and Amalie Krochmal-Kolboshover, and their 3 children, Siegfried, Rénate and Liane. They fled flee their home in 1938 to Belgium but were subsequently arrested and imprisoned in the Rivesaltes concentration camp`,
        biography: `Eight-year-old Rénate and six-year-old Liane Krochmal, who lived in the children's home in Izieu, France, were deported to their deaths on 13 April 1944. In 1938, Yaakov and Amalie Krochmal-Kolboshover fled from Vienna, Austria to Belgium with their 3 children, Siegfried (b. 1931), Rénate (b. 1935) and Liane (b. 1937). Jacob had a brother and a sister in the US, but all his attempts to obtain entry permits for himself and his family proved unsuccessful.
         The Krochmal family was arrested and interned in the Rivesaltes concentration camp, where Siegfried perished.  His parents were transferred to Drancy and in September 1942, they were deported to Auschwitz, where they were murdered. It is not known when and how Rénate and Liane came to the children's home in Izieu, but they were caught in the raid, and deported with all the other children in the home to the extermination camps in Eastern Europe, where they were murdered.`
    },
    {
        id: 2, position: { lat: 48.21634079932238, lng: 16.37563762593169 }, videoUrl:"/zone2/seg_2.mp4", audioUrl: "/zone2/seg_2.mp3", thumbnailUrl: "/zone2/seg_2_tn.jpg", imageUrl: "/zone2/seg_2.jpg",
        address: "Große Schiffgasse 8", locationName: "Schiffschul", name: "Schiffschul",
        description: `Schiffschul was the former Orthodox congregation and synagogue destroyed by the Nazis on Kristallnacht in 1938.`,
        biography: `The Schiffschul was named after the street  Große Schiffgasse in which it was located. From 1864 to 1938 it was the center of strictly orthodox, Torah-observant Judaism in Vienna. The synagogue, which was inaugurated in 1864, was joined by other institutions, such as a teaching house, a religious school, Orthodox Jewish associations, a kosher bakery and a kosher meat shop. The Schiffschul was mainly visited by Hungarian and Slovakian Jews who had immigrated from the crown land of Hungary.
         As early as October 1938, there were riots against synagogues, prayer houses and Jewish institutions. On October 15, 1938, some members of the SA stormed into the Schiffschul, desecrated the Torah shrine, destroyed furnishings and took away cult objects. During the November pogrom on November 10, 1938, the Schiffschul was completely destroyed by arson and hand grenades.`
    },
    {
        id: 3, position: { lat: 48.217161813990174, lng: 16.38068770010917 }, videoUrl:"/zone2/seg_3.mp4", audioUrl: "/zone2/seg_3.mp3", thumbnailUrl: "/zone2/seg_3_tn.jpg", imageUrl: "/zone2/seg_3.jpg",
        address: "Taborstraße 23", locationName: "Julius Ofner Denkmal", name: "Julius Ofner",
        description: `The Denkmal, or monument, to Austrian lawyer Julius Ofner was first erected in 1932 in Taborstraße. It was removed by the Nazis in 1943 and later permanently rebuilt in 1954`,
        biography: `Julius Ofner was born in 1845 in Bohemia to a Jewish merchant and farmer. He grew up to become a lawyer, social politician, legal philosopher, member of the House of Representatives of the Vienna Imperial Council and an important representative of liberalism in Austria.
         Ofner played a key role in the amendment of the General Civil Code, in the development of labor law (including the ban on child labor, Sunday rest regulations, the admission of women to certain professions, care for released prisoners) and in the reform of the criminal law . These reformsc were unofficially named "Lex Ofner" after him. 
         Thanks to his contributions, a monument was erected in his honor in 1932.`
    },
    {
        id: 4, position: { lat: 48.217534742224615, lng: 16.38185301058902 }, videoUrl:"/zone2/seg_4.mp4", audioUrl: "/zone2/seg_4.mp3", thumbnailUrl: "/zone2/seg_4_tn.jpg", imageUrl: "/zone2/seg_4.jpg",
        address: "Glockengasse 4", locationName: "Former Home of H. Finkelbloch", name: "Helmuth Finkelbloch",
        description: `Hellmuth Finkelbloch, along with his entire family, was deported on Nov 23, 1941 to Kowno, where they were murdered shortly afterwards.`,
        biography: `Hellmuth Finkelbloch, along with his entire family, was deported on Nov 23, 1941 to Kowno, where they were murdered shortly afterwards.`
    },
    {
        id: 5, position: { lat: 48.21811349740669, lng: 16.3821363970962 }, videoUrl:"/zone2/seg_5.mp4", audioUrl: "/zone2/seg_5.mp3", thumbnailUrl: "/zone2/seg_5_tn.jpg", imageUrl: "/zone2/seg_5.jpg",
        address: "Rotensterngasse 7",  locationName: "Former Home of Georg Agatstein", name: "Georg Agatstein",
        description: `Georg Agatstein lived on the Rotensterngasse 7 together with his maternal grandfather Salomom Beer.`,
        biography: `Georg Karl and his parents were born in Vienna. His father, Josef Agatstein, handed in a request to 
         migrate and so did his aunt Gisele Beer. Georg lived with his maternal grandmother Salomon Beer
          prior to their deportation on the 24th of Sep 1942.`
    },
    {
        id: 6, position: { lat: 48.21658949395205, lng: 16.386699825931668 }, videoUrl:"/zone2/seg_6.mp4", audioUrl: "/zone2/seg_6.mp3", thumbnailUrl: "/zone2/seg_6_tn.jpg", imageUrl: "/zone2/seg_6.jpg",
        address: "Rotensterngasse 37", locationName: "Former Home of Manfred Papo", name: "Manfred Papo",
        description: `Dr. Manfred Papo was Chief Rabbi of the Sephardic Community in Vienna. He was imprisoned in Dachau and escaped to the UK where he served as Sephardic Rabbi in Withington, Manchester. He was able to return to Vienna in 1966 where he rests today.`,
        biography: `Dr. Manfred Papo (1898-1966) was Chief Rabbi of the Sephardic Community in Vienna - and an active teacher. He was imprisoned in Dachau and managed toescape to UK, where he served as Sephardic Rabbi in Withington, Manchester, and from 1944-1964 as Rabbi of the Sephardic Community in
         Salisbury, Rhodesia. Returned to Vienna and passed away on the 15th of May 1966. He is buried in Vienna.`
    },
    {
        id: 7, position: { lat: 48.22010716020932, lng: 16.383484439339778 }, videoUrl:"/zone2/seg_7.mp4", audioUrl: "/zone2/seg_7.mp3", thumbnailUrl: "/zone2/seg_7_tn.jpg", imageUrl: "zone2/seg_7.jpg",
        address: "Grosse Stadtgutgasse 20", locationName: "Former Home of Albert Berger", name: "Albert Rubin Berger",
        description: `Albert Rubin Berger was only 3 years old when he and his family were deported to Opale, Poland in 1941, where they perished`,
        biography: `On May 19, 1938 Albert's father Johann Berger handed the authorities in Vienna a formal request for emigration to Palestine (British Mandate). 
         The request included himself, his wife, his newborn son Albert and his sister-in-law. He listed several friends in Palestine who would support him. The request was apparently not granted, because the family was deported to Opale, Poland and perished there. One can imagine the stress the family went through waiting for the answer to their request which, if granted, would have saved their lives.`
    },
    {
        id: 8, position: { lat: 48.220405885114936, lng: 16.384570868260532 }, videoUrl:"/zone2/seg_8.mp4", audioUrl: "/zone2/seg_8.mp3", thumbnailUrl: "/zone2/seg_8_tn.jpg", imageUrl: "/zone2/seg_8.jpg",
        address: "Pazmanitengasse 4", locationName: "Former Home of Dora Schwam", name: "Dora Schwam",
        description: `In 1941, Dora Schwam's family was deported from Vienna to Poland's Lodz ghetto.`,
        biography: `Dora, together with her mother Jenta and brother Max, was deported from their Vienna home to the Lodz ghetto (Litzmannstadt) in Poland in November 1941. 
         The terrible conditions in the Lodz ghetto are well known and one can only imagine the hardship Dora, her mother and brother went through. 
         The precise circumstances of her death are not known but she did not survive the Holocaust. Her mother was eventually transported to the Chelmno camp and murdered there in May 1942. 
         It is possible that Dora and Max met the same fate.`
    }
    ]
    },   
    {
      id: 2,
      district: 'Innere Stadt', 
      zone: '[Zone 1]', 
      stops: 10, 
      distance: '2km', 
      walkingTime: '30 minutes',
      locations: [
        {
            id: 1, position: { lat: 48.215732984277956, lng: 16.37537687797315 }, videoUrl: "../zone2/seg_1.mp4", audioUrl: "../zone2/seg_1.mp3", address: "Große Schiffgasse 2", 
         locationName: "", name: "",
         description: ``,
         biography: ``
        },
        {
            id: 2, position: { lat: 48.21634079932238, lng: 16.37563762593169 }, videoUrl: "../zone2/seg_2.mp4", audioUrl: "../zone2/seg_2.mp3", address: "Große Schiffgasse 8", name: "Schiffschul", description: "Schiffschul, officially Khal Adas Yisroel, was the former Orthodox congregation and synagogue, located at Grosse Schiffgasse 8. The synagogue was destroyed by the Nazis on Kristallnacht in 1938."
        },
        {
            id: 3, position: { lat: 48.217161813990174, lng: 16.38068770010917 }, videoUrl: "../zone2/seg_3.mp4", audioUrl: "../zone2/seg_3.mp3", address: "Taborstraße 23", name: "Julius Ofner Denkmal", description: "A monument erected in 1932 in Taborstraße to Austrian lawyer Julius Ofner was removed by the Nazis in 1943 and later permanently rebuilt in 1954"
        },
        {
            id: 4, position: { lat: 48.217534742224615, lng: 16.38185301058902 }, videoUrl: "../zone2/seg_4.mp4", audioUrl: "../zone2/seg_4.mp3", address: "Glockengasse 4", name: "Home of Hellmuth Finkelbloch", description: "https://simonwiesenthal-galicia-ai.com/swiggi/images/tour9vienna.pdf"
        },
        {
            id: 5, position: { lat: 48.21811349740669, lng: 16.3821363970962 }, videoUrl: "../zone2/seg_5.mp4", audioUrl: "../zone2/seg_5.mp3", address: "Rotensterngasse 7",  name: "Home of Georg Agatstein", description: "https://simonwiesenthal-galicia-ai.com/swiggi/images/tour9vienna.pdf"
        },
        {
            id: 6, position: { lat: 48.21658949395205, lng: 16.386699825931668 }, videoUrl: "../zone2/seg_6.mp4", audioUrl: "../zone2/seg_6.mp3", address: "Rotensterngasse 37", name: "Home of Rabbi Manfred Papo", description: "Dr. Manfred Papo was Chief Rabbi of the Sephardic Community in Vienna. He was imprisoned in Dachau and escaped to the UK where he served as Sephardic Rabbi in Withington, Manchester. He was able to return to Vienna in 1966 where he rests today."
        },
        {
            id: 7, position: { lat: 48.22010716020932, lng: 16.383484439339778 }, videoUrl: "../zone2/seg_7.mp4", audioUrl: "../zone2/seg_7.mp3", address: "Grosse Stadtgutgasse 20", name: "Home of Albert Rubin Berger", description: "On May 19, 1938 Albert's father Johann Berger handed the authorities in Vienna a formal request for emigration to Palestine (British Mandate). The request included himself, his wife, his son Albert and his sister-in-law. He listed several friends in Palestine who would support him. The request was not granted and the family was deported to Opale, Poland and perished there."
        },
        {
            id: 8, position: { lat: 48.220405885114936, lng: 16.384570868260532 }, videoUrl: "../zone2/seg_8.mp4", audioUrl: "../zone2/seg_8.mp3", address: "Pazmanitengasse 4", name: "Home of Dora Schwam", description: "Dora, together with her mother Jenta and brother Max, was deported from their Vienna home to the Lodz ghetto (Litzmannstadt) in Poland in November 1941."
        }
      ]
      }
  ]