import React from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

export const RouteLabelOverlay = ({ position, content }) => {
  return (
    <AdvancedMarker position={position}>
      <div style={{
        position: 'absolute',
        transform: 'translate(-100%, -50%)',
        marginLeft: '-10px', // Move the box to the left
        display: 'flex',
        alignItems: 'center'
      }}>
        <div style={{
          backgroundColor: '#4CAF50', // Green background
          color: 'white', // White text
          padding: '5px 10px',
          borderRadius: '4px',
          boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
          whiteSpace: 'nowrap'
        }}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <div style={{
          width: 0,
          height: 0,
          borderTop: '10px solid transparent',
          borderBottom: '10px solid transparent',
          borderLeft: '10px solid #4CAF50', // Green arrow pointing to the route
          marginLeft: '-1px' // Slight adjustment to connect arrow to box
        }} />
      </div>
    </AdvancedMarker>
  );
};