import React, { useRef, useState, useEffect, useCallback } from "react";
import { useParams, Link } from 'react-router-dom';
import { APIProvider, Map, Marker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs, faHouse } from '@fortawesome/free-solid-svg-icons';
import { tours } from "./data/TourData";
import { getDistanceFromCoords } from "./utility/helper";
import { LoadingSpinner } from "./components/LoadingSpinner";
import { RouteLabelOverlay } from "./components/RouteLabelOverlay";
import './MapPage.css';

const MapContent = ({ defaultCenter, userLocation, setUserLocation, userHeading, setUserHeading, locations, selectedLocation, setSelectedLocation, locationError, setLocationError, setNavigationCallback, setStartWatchingPositionCallback }) => {
  const map = useMap();
  const coreLibrary = useMapsLibrary('core');
  const routesLibrary = useMapsLibrary('routes');
  const mapsLibrary = useMapsLibrary('maps');
  const geometryLibrary = useMapsLibrary('geometry');
  const [showWarning, setShowWarning] = useState(false);
  const [pathPolyline, setPathPolyline] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const positionReceivedRef = useRef(false);
  const [userIcon, setUserIcon] = useState(null);
  const [headingIcon, setHeadingIcon] = useState(null);
  const watchIdRef = useRef(null);
  const [isRecentering, setIsRecentering] = useState(false);
  const [navigationPolyline, setNavigationPolyline] = useState(null);
  const [routeError, setRouteError] = useState(null);
  const [route, setRoute] = useState(null);
  const [routeInfo, setRouteInfo] = useState(null);
  const [routeLength, setRouteLength] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [isNavigationActive, setIsNavigationActive] = useState(false);
  const [destination, setDestination] = useState(null);
  const isFirstPressRef = useRef(true);
  const recenterTimeoutRef = useRef(null);
  const listenerRef = useRef(null);

  const clearNavigation = useCallback(() => {
    if (navigationPolyline) {
      navigationPolyline.setMap(null);
      setNavigationPolyline(null);
    }
    setRoute(null);
    setRouteInfo(null);
    setRouteLength(0);
    setShowOverlay(false);
    setDestination(null);
    setIsNavigationActive(false);
  }, [navigationPolyline]);

  const updateOverlayVisibility = useCallback((length, zoom) => {
    setShowOverlay((zoom > 14 && length > 150) || (zoom > 18));
  }, []);

  const handleNavigationClick = useCallback(async (start, end) => {
    if (start === null && end === null) {
      clearNavigation();
      return;
    }

    if (!routesLibrary || !mapsLibrary || !map) {
      console.error('Required libraries or map not loaded');
      setRouteError('Map services are not fully loaded. Please try again.');
      return;
    }

    clearNavigation();
    setRouteError(null);
  
    if (!end || !end.lat || !end.lng) {
      console.error('Invalid end location. Please try again.');
      setRouteError('Invalid end location. Please try again.');
      return;
    }

    setDestination(end);
    setIsNavigationActive(true);
  
    let origin;
    try {
      // If start is provided, use it; otherwise, get the current user location
      if (start && start.lat && start.lng) {
        origin = start;
      } else {
        // Set loading state
        setIsLoading(true);
  
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
          });
        });
  
        origin = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
  
        // Update user location state
        setUserLocation(origin);
      }
    } catch (error) {
      console.error('Error getting user location:', error);
      setRouteError('Unable to get your current location. Please try again.');
      setIsLoading(false);
      return;
    }
  
    // Set loading state to false after getting location
    setIsLoading(false);
  
    const directionsService = new routesLibrary.DirectionsService();
  
    const destination = { lat: end.lat, lng: end.lng };
  
    try {
      const result = await new Promise((resolve, reject) => {
        directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: routesLibrary.TravelMode.WALKING,
          },
          (result, status) => {
            if (status === 'OK') {
              resolve(result);
            } else {
              reject(status);
            }
          }
        );
      });
  
      if (result.routes[0]) {
        const route = result.routes[0];
        setRoute(route.overview_path);
        const polyline = new mapsLibrary.Polyline({
          path: route.overview_path,
          geodesic: true,
          strokeColor: '#FFF',
          strokeOpacity: 1,
          strokeWeight: 4,
          zIndex: 2500,
          icons: [{
            icon: {
              path: 'M -1,0 A 1,1 0 0 0 1,0 A 1,1 0 0 0 -1,0', // Custom circle path
              fillColor: '#4CAF50',
              fillOpacity: 1,
              strokeWeight: 2,
              scale: 6  // Adjust this value to change the size of the dots
            },
            offset: '0',
            repeat: '15px' 
          }],
        });
        if (map) {
          polyline.setMap(map);
        }
        setNavigationPolyline(polyline);
        const distanceInMeters = route.legs[0].distance.value;
        setRouteLength(distanceInMeters);
        
        let totalDistance = 0;
        const path = route.overview_path;
        for (let i = 1; i < path.length; i++) {
          totalDistance += geometryLibrary.spherical.computeDistanceBetween(
            path[i-1],
            path[i]
          );
        }
  
        // Find the point that's halfway along the actual path
        let currentDistance = 0;
        let midpoint;
        for (let i = 1; i < path.length; i++) {
          const segmentDistance = geometryLibrary.spherical.computeDistanceBetween(
            path[i-1],
            path[i]
          );
          if (currentDistance + segmentDistance > totalDistance / 2) {
            const remainingDistance = (totalDistance / 2) - currentDistance;
            const fraction = remainingDistance / segmentDistance;
            midpoint = geometryLibrary.spherical.interpolate(
              path[i-1],
              path[i],
              fraction
            );
            break;
          }
          currentDistance += segmentDistance;
        }
  
        const distance = route.legs[0].distance.text;
        const duration = route.legs[0].duration.text;
        setRouteInfo({
          position: midpoint,
          content: `<strong>${distance}</strong> <br><strong>${duration}</strong>`,
        });
  
        map.fitBounds(route.bounds);
        updateOverlayVisibility(distanceInMeters, map.getZoom());
        map.panTo(midpoint);
        map.setZoom(16);
      }
    } catch (status) {
      if (status === 'ZERO_RESULTS') {
        setRouteError('No walking route found between these locations. The distance might be too great or there might not be a pedestrian path.');
      } else {
        setRouteError(`Unable to find a route: ${status}`);
      }
    }
  }, [routesLibrary, mapsLibrary, geometryLibrary, map, clearNavigation, updateOverlayVisibility, setUserLocation, setIsLoading]);

  const handlePositionUpdate = useCallback((position) => {
    const newLocation = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };
    setUserLocation(newLocation);
    setLocationError(null);
    if (!positionReceivedRef.current) {
      positionReceivedRef.current = true;
      setIsLoading(false);
    }
    if (position.coords.heading !== null) {
      setUserHeading(position.coords.heading);
    }
    if (isFirstPressRef.current && map) {
      map.panTo(newLocation);
      map.setZoom(18);
      isFirstPressRef.current = false;
    }
    if (isNavigationActive && destination) {
      const distanceToDestination = getDistanceFromCoords(
        newLocation.lat, newLocation.lng,
        destination.lat, destination.lng
      );

      if (distanceToDestination < 1) {
        clearNavigation();
        alert("You have reached your destination!");
      } else {
        handleNavigationClick(newLocation, destination);
      }
    }
  }, [map, setUserLocation, setLocationError, handleNavigationClick, destination, clearNavigation, isNavigationActive]);

  const stopWatchingPosition = useCallback(() => {
    if (watchIdRef.current !== null) {
      navigator.geolocation.clearWatch(watchIdRef.current);
      watchIdRef.current = null;
      setIsLoading(false);
    }
  }, []);
  
  const handlePositionError = useCallback((error) => {
    console.error("Error getting user location:", error);
    let errorMessage = "An error occurred while getting your location:";
    switch(error.code) {
      case error.PERMISSION_DENIED:
        errorMessage += "Location access denied. Please enable location services in your browser settings";
        break;
      case error.TIMEOUT:
        errorMessage += "The request to get your location timed out. Please try again.";
        break;
      default:
        errorMessage += "An unknown error occurred.";
    }
    setIsLoading(false);
    setLocationError(errorMessage);
  }, [setLocationError]);
  
  const startWatchingPosition = useCallback(() => {
    if (watchIdRef.current === null) {
      setIsLoading(true);
      positionReceivedRef.current = false;
      watchIdRef.current = navigator.geolocation.watchPosition(
        handlePositionUpdate,
        handlePositionError,
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }
      );
      setTimeout(() => {
        if (isLoading) {
          setIsLoading(false);
          console.log("Geolocation is taking longer than expected.");
          stopWatchingPosition();
        }
      }, 20000); // 20 seconds timeout
    }
  }, [handlePositionUpdate, handlePositionError, isLoading, stopWatchingPosition]);
  
  const centerMapOnUser = useCallback(() => {
    if (!userLocation) {
      startWatchingPosition();
      return;
    } else if (map) {
      map.panTo(userLocation);
      map.setZoom(18);
    }
  }, [userLocation, map, startWatchingPosition]);
  
  const centerMapOnOriginal = useCallback(() => {
    if (map) {
      setIsRecentering(true);
      if (recenterTimeoutRef.current) {
        clearTimeout(recenterTimeoutRef.current);
      }
      map.panTo(defaultCenter);
      map.setZoom(15);
      recenterTimeoutRef.current = setTimeout(() => {
        setIsRecentering(false);
      }, 5000);
    }
  }, [map, defaultCenter]);

  useEffect(() => {
    setNavigationCallback(() => handleNavigationClick);
  }, [setNavigationCallback, handleNavigationClick]);

  useEffect(() => {
    setStartWatchingPositionCallback(() => startWatchingPosition);
  }, [setStartWatchingPositionCallback, startWatchingPosition]);

  useEffect(() => {
    if (map) {
      map.setOptions({
        clickableIcons: false,
      });
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      const handleZoomChanged = () => {
        const currentZoom = map.getZoom();
        updateOverlayVisibility(routeLength, currentZoom);
      };
      listenerRef.current = map.addListener('zoom_changed', handleZoomChanged);
      handleZoomChanged();
      return () => {
        if (listenerRef.current) {
          window.google.maps.event.removeListener(listenerRef.current);
        }
      };
    }
  }, [map, routeLength, updateOverlayVisibility]);

  useEffect(() => {
    if (coreLibrary) {
      setUserIcon({
        path: coreLibrary.SymbolPath.CIRCLE,
        scale: 8,
        fillColor: "#4285F4",
        fillOpacity: 1,
        strokeColor: "#ffffff",
        strokeWeight: 2,
      });
    }
  }, [coreLibrary, userHeading]);

  useEffect(() => {
    if (coreLibrary) {
      const headingRadians = (userHeading || 0) * (Math.PI / 180);
      const offsetDistance = 6.5; // Adjust this value to change the distance from the center

      const offsetX = Math.sin(headingRadians) * offsetDistance;
      const offsetY = Math.cos(headingRadians) * offsetDistance;

      setHeadingIcon({
        path: coreLibrary.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 4,
        fillColor: "#4285F4",
        fillOpacity: 1,
        strokeColor: "#ffffff",
        strokeWeight: 1,
        rotation: userHeading || 0,
        anchor: new coreLibrary.Point(offsetX, offsetY),
      });
    }
  }, [coreLibrary, userHeading]);

  useEffect(() => {
    if (userLocation && map) {
      const mapCenter = map.getCenter();
      const distance = getDistanceFromCoords(
        userLocation.lat, userLocation.lng,
        mapCenter.lat(), mapCenter.lng()
      );
      setShowWarning(distance > 10);
    }
  }, [userLocation, map]);

  useEffect(() => {
    window.addEventListener('beforeunload', stopWatchingPosition);
    return () => {
      window.removeEventListener('beforeunload', stopWatchingPosition);
      stopWatchingPosition();
    };
  }, [stopWatchingPosition]);

  const hideWarning = () => {
    setShowWarning(false);
  }

  const createPolyline = useCallback((path, color = "#FF0000") => {
    try {
      return new mapsLibrary.Polyline({
        path: path,
        geodesic: true,
        strokeColor: color,
        strokeOpacity: 1.0,
        strokeWeight: 5
      });
    } catch (error) {
      console.error("Error creating polyline:", error);
      return null;
    }
  }, [mapsLibrary]);

  const calculateAndDrawRoute = useCallback((start, end, waypoints, color) => {
    const directionsService = new routesLibrary.DirectionsService();
    
    directionsService.route({
      origin: start,
      destination: end,
      waypoints: waypoints,
      travelMode: routesLibrary.TravelMode.WALKING,
    }, (result, status) => {
      if (status === 'OK' && result.routes[0]) {
        const newPolyline = createPolyline(result.routes[0].overview_path, color);
        if (map) {
          newPolyline.setMap(map);
        }
        setPathPolyline(prevPolylines => [...prevPolylines, newPolyline]);
      } else {
        console.error('Directions request failed due to ' + status);
      }
    });
  }, [routesLibrary, map, createPolyline, setPathPolyline]);

  useEffect(() => {
    if (routesLibrary && mapsLibrary && map && locations.length > 2) {
      setPathPolyline(prevPolylines => {
        prevPolylines.forEach(polyline => polyline.setMap(null));
        return [];
      });
      calculateAndDrawRoute(
        locations[0].position,
        locations[2].position,
        [{ location: locations[1].position, stopover: true }],
      );
      if (locations.length > 3) {
        calculateAndDrawRoute(
          locations[2].position,
          locations[locations.length - 1].position,
          locations.slice(3, -1).map(location => ({
            location: location.position,
            stopover: true
          })),
        );
      }
    }

    return () => {
      setPathPolyline(prevPolylines => {
        if (prevPolylines) {
          prevPolylines.forEach(polyline => {
            if (polyline) polyline.setMap(null);
          });
        }
        return [];
      });
    };
  }, [map, routesLibrary, mapsLibrary, calculateAndDrawRoute, locations]);

  return (
    <>
      {showOverlay && routeInfo && (
        <RouteLabelOverlay
          position={routeInfo.position}
          content={routeInfo.content}
        />
      )}
    {routeError && (
      <div className="error-message" onClick={() => setRouteError(null)}>
        {routeError}
      </div>
    )}
      {isLoading && (
        <LoadingSpinner></LoadingSpinner>
      )}

      <div className='custom-marker'>
      <button 
        onClick={centerMapOnUser}
      >
        <FontAwesomeIcon icon={faCrosshairs} />
      </button>
      <button
        onClick={centerMapOnOriginal}
      >
        <FontAwesomeIcon icon={faHouse} />
      </button>

    </div>

      {userLocation && (
          <Marker
            position={userLocation}
            icon={{
            ...userIcon,
            rotation: userHeading
          }}
          />
        )}

        {userLocation && (
          <Marker
            position={userLocation}
            icon={{
            ...headingIcon,
            rotation: userHeading
          }}
          />
        )}
      {showWarning && (
        <div className="error-message" onClick={hideWarning}>
          Warning: You're outside the range of the tour area
        </div>
      )}
    </>
  );
};

const MapPage = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [userHeading, setUserHeading] = useState(0);
  const [locationError, setLocationError] = useState(null);
  const [mapHeight, setMapHeight] = useState('100vh');
  const [navigationCallback, setNavigationCallback] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [startY, setStartY] = useState(null);
  const [currentY, setCurrentY] = useState(null);
  const [navigatingToLocation, setNavigatingToLocation] = useState(null);
  const [startWatchingPositionCallback, setStartWatchingPositionCallback] = useState(null);
  const [isWaitingForLocation, setIsWaitingForLocation] = useState(false);
  
  const { tourId } = useParams();
  const windowRef = useRef(null);
  const tour = tours.find(t => t.id.toString() === tourId);

  const locations = tour ? tour.locations : [];

  const defaultCenter = tour ? tour.center : {
    lat: 48.210033, 
    lng: 16.363449
  }

  const mapContainerStyle = {
    width: '100%',
    height: '100vh'
  };

  const defaultZoom = 15;

  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
  };

  const handleMapClick = () => {
    setSelectedLocation(null);
  };

  const handleInfoWindowClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const adjustMapHeight = () => {
      const windowHeight = window.innerHeight;
      setMapHeight(`${windowHeight}px`);
    };
    adjustMapHeight();
    window.addEventListener('resize', adjustMapHeight);
    return () => window.removeEventListener('resize', adjustMapHeight);
  }, []);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
    setCurrentY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    setCurrentY(e.touches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (startY === null || currentY === null) return;

    const diff = currentY - startY;
    const threshold = window.innerHeight * 0.2; // 20% of screen height

    if (isExpanded && diff > threshold) {
      setIsExpanded(false);
    } else if (!isExpanded && diff < -threshold) {
      setIsExpanded(true);
    } else if (!isExpanded && diff > threshold) {
      setSelectedLocation(null); // Close the window by clearing the selected location
    }

    setStartY(null);
    setCurrentY(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (windowRef.current && !windowRef.current.contains(event.target)) {
        setSelectedLocation(null); // Close the window by clearing the selected location
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setSelectedLocation]);

  const handleErrorClick = () => {
    setLocationError(null);
  }

  const handleNavigationClick = useCallback((start, end) => {
    if (navigationCallback) {
      navigationCallback(start, end);
    }
  }, [navigationCallback]);

  const handleGoStopClick = useCallback(() => {
    if (navigatingToLocation && navigatingToLocation.id === selectedLocation.id) {
      // Stop navigation
      setNavigatingToLocation(null);
      if (navigationCallback) {
        navigationCallback(null, null);
      }
    } else {
      // Start navigation
      setNavigatingToLocation(selectedLocation);
      setIsWaitingForLocation(true);

      if (!userLocation) {
        if (startWatchingPositionCallback) {
          startWatchingPositionCallback();
        }
      } else {
        // If we already have the location, use it immediately
        if (navigationCallback) {
          navigationCallback(userLocation, selectedLocation.position);
        }
        setIsWaitingForLocation(false);
      }
    }
  }, [navigatingToLocation, selectedLocation, navigationCallback, startWatchingPositionCallback, userLocation]);

  useEffect(() => {
    if (isWaitingForLocation && userLocation) {
      if (navigationCallback) {
        navigationCallback(userLocation, selectedLocation.position);
      }
      setIsWaitingForLocation(false);
    }
  }, [isWaitingForLocation, userLocation, navigationCallback, selectedLocation]);

  const isNavigatingToThisLocation = navigatingToLocation && selectedLocation && navigatingToLocation.id === selectedLocation.id;


    return (
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <div style={{ 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        right: 0, 
        bottom: 0, 
        overflow: 'hidden'
      }}>
        <Map
          mapId="63a30e3caae21e82"
          style={mapContainerStyle}
          defaultCenter={defaultCenter}
          defaultZoom={defaultZoom}
          onClick={handleMapClick}
          disableDefaultUI
        >
        {locations.map(location => (
          <Marker
            key={location.id}
            position={location.position}
            label={{
              text: location.id.toString(),
              fontFamily: "Inter",
              fontWeight: "bold"
              }}
            onClick={() => handleMarkerClick(location)}
          />
          
        ))}
        {selectedLocation && (
          <div 
            className={`custom-info-window ${isExpanded ? 'expanded' : ''}`}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd} 
            onClick={handleInfoWindowClick}
          >
            <div className='card-title-container'>
              <div className='card-title'>
                <h2>{selectedLocation.address}</h2>
                <h4>{selectedLocation.locationName}</h4>
              </div>
              
            <div
              className={`navigation-button ${isNavigatingToThisLocation ? 'stop' : 'go'}`}
              onClick={handleGoStopClick}
            >
              {isNavigatingToThisLocation ? 'Stop' : 'Go'}
            </div>
        </div>
            <div className="thumbnail">
              <img src={selectedLocation.thumbnailUrl} alt="thumbnail"></img>
            </div>
            <div className="audio-section">
              <h4>Listen to the Companion Narration </h4>
              <audio className="audio-player" controls>
                <source src={selectedLocation.audioUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
            <p>{selectedLocation.description}</p>

            <Link to={`/bio/${tourId}/${selectedLocation.id}`}>
              <button className='audio-button'>Watch the Video Narration</button>
            </Link>
          </div>
        )}
          <MapContent
            defaultCenter={defaultCenter}
            userLocation={userLocation}
            setUserLocation={setUserLocation}
            userHeading={userHeading}
            setUserHeading={setUserHeading}
            locations={locations}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            locationError={locationError}
            setLocationError={setLocationError}
            setNavigationCallback={setNavigationCallback}
            setStartWatchingPositionCallback={setStartWatchingPositionCallback}
          />
        </Map>
        {locationError && (
        <div className="error-message" onClick={handleErrorClick}>
          {locationError}
        </div>
      )}
      </div>
      </APIProvider>
    );
  };
  
  export default MapPage;