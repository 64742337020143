import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { tours } from './data/TourData';
import './BioPage.css';

const PersonProfile = ({ locationName, address, biography, videoUrl, imageUrl }) => {
  const [isReadMore, setIsReadMore] = useState(false);

  return (
    <div className='profile-card'>
      <div className='video-container'>
        <ReactPlayer
          url={videoUrl}
          controls={true}
          width="100%"
          height="100%"
          className='react-player'
        />
      </div>
      <h2 className='address'>{address}</h2>
      <div className='content-container'>
        <img src={imageUrl} alt={`picture of ${locationName}`} className='portrait' />
        <p className='biography'>
          {isReadMore ? biography : `${biography.slice(0, 130)}...`}
          <button 
            className='read-more-btn'
            onClick={() => setIsReadMore(!isReadMore)}
          >
            {isReadMore ? 'Read Less' : 'Read More'}
          </button>
        </p>
      </div>
    </div>
  );
};

const BioPage = () => {
  const { tourId, locationId } = useParams();

  const tour = tours.find(t => t.id.toString() === tourId);
  const location = tour ? tour.locations.find(l => l.id.toString() === locationId) : null;

  if (!tour || !location) {
    return <div>Location not found</div>;
  }

  return (
    <div>
      <Link to={`/map/${tourId}`} className='back-to-map-link'>
        <FontAwesomeIcon icon={faArrowLeft} /> Back to Map
      </Link>
      <div className='bio-container'>
        <PersonProfile
          locationName={location.locationName}
          address={location.address}
          biography={location.biography}
          videoUrl={location.videoUrl}
          imageUrl={location.imageUrl}
        />
      </div>
    </div>
  );
};

export default BioPage;